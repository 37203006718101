.heroContainer {
  text-align: center;
  /* font-size: small; */

  /* background: black;
  -webkit-transition: background 6s; /* For Safari 3.0 to 6.0 */
  /* transition: background 6s; */
}

.hero_img {
  margin-top: 10%;
}

.bgContainerHero {
  background: linear-gradient(
    -45deg,
    #31333c,
    #1d2a38,
    rgb(17, 48, 84),
    #182336,
    #336092,
    #336092,
    #336092
  );
  background-size: 400% 400%;
  animation: Gradient 50s ease infinite;
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* .bgContainer {
  background: linear-gradient(
    -45deg,
    #010e57,
    hsl(235, 90%, 30%),
    #ffffff,
    #a7b7ff,
    #030c6e
  ); */
/* width: 100px;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px; */
/*}*/
.title {
  margin-top: 10px;
  color: white;
  font-size: 40px;
  font-weight: bold !important;
  text-shadow: 60px;
  font-weight: 100;
}
.subtitle {
  font-size: 30px;
  color: white;
  font-weight: 50;
}

.heroText {
}

.bck {
  /* padding-top: 100%; */
}

.aa {
  color: black !important;
  font-weight: bold !important;
}
.btn {
  width: 200px;
  background-color: white !important;
}

.custom-btn {
  color: black !important;
  background-color: white !important;
  border-color: white !important;

  border-radius: 14px !important;
  padding: 5px 10px !important;
  text-transform: uppercase !important;
}

.custom-btn:hover {
  background-color: #2e1a74 !important;
  outline: #ffffff !important;
}

.custom-btn:active {
  border-color: white !important;
}

.custom-btn:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 560px) {
  .hero_img {
    margin-top: 20%;
  }

  .title {
    margin-top: 30px;
    color: white;
    font-size: 30px;
    font-weight: bold !important;
    text-shadow: 60px;
    font-weight: 100;
  }
  .subtitle {
    font-size: 20px;
    color: white;
    font-weight: 50;
  }

  .bck {
    padding-top: 100%;
  }
}
