.picFooter {
  width: 950px;
  margin-left: 12% !important;
  margin-top: -20px !important;
  /* margin-bottom: -300px; */
}
/* .footerContainer {
  padding-bottom: 500px !important;
} */

.footerTitles {
  text-shadow: 0 1px 3px rgb(0 0 0 / 60%) !important;
  font-weight: bold;
  color: white;
}
.quote{
  font-style: ;
}

@media (max-width: 560px) {
  .hrColor {
    background-color: #e0e0cd !important;
  }
  .footerLower {
    margin-top: 20px !important;
  }

  .picFooter {
    width: 380px;
    margin-left: -15px !important;
  }
  .footerContainer {
    padding-bottom: 300px;
  }
}
