.serviceCardContainer {
  justify-content: center !important;
  padding-top: 50px;
  width: 1100px !important;
  /* background-color: white !important; */
  padding-bottom: 230px;
  color: white;
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}
.swiper-pagination {
  margin-left: -50px !important;
}

.myswiper {
  margin-left: 135px !important;
}

.titleServices {
  margin-top: 50px !important;
  margin-bottom: 40px;
  text-align: left;
  font-weight: bold;
  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
  font-size: 50px !important;
}

@media (max-width: 560px) {
  .myswiper {
    margin-left: 11.5% !important;
  }
  .titleServices {
    font-size: 18px;
    margin-top: 60px;
    margin-right: auto;
    text-align: center;
  }

  .swiper-pagination {
    margin-left: -30px !important;
    margin-bottom: -10px !important;
  }
  .serviceCardContainer {
    justify-content: center !important;
    padding-top: 50px;
    width: auto !important;

    /* background-color: white !important; */
    padding-bottom: 230px;
    color: white;
  }
}

/* .background {
    width: max-content;
  } */

/* .bgContainer {
  background: linear-gradient(
    -45deg,
    #336092,
    rgb(51, 96, 146),
    #336092,
    #336092,
    #336092
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
} */
/* width: 100px;
    height: 100px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px; */
