.landingPageContainer {
  justify-content: center;
  padding-top: 50px;

  padding-bottom: 230px;
  color: white;
}

/* .bgContainer {
  background: linear-gradient(
    to right,
    -45deg,
    #243c3f,
    rgb(51, 96, 146),
    #336092,
    #336092,
    #243c3f
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */
.subtitle {
  text-align: center;
  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
  font-size: larger !important;
  margin-right: 100px;
}

.title {
  margin-top: 80px !important;
  text-align: center;
  margin-right: 100px;
  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
  font-size: 40px !important;
}

.Logos {
  margin-top: 50px;
  width: 400px;
  margin-left: 150px;
  border-radius: 40px;
}

@media (max-width: 560px) {
  .title {
    font-size: 18px;
    margin-top: 60px;
    margin-right: auto;
    text-align: center;
  }

  .subtitle {
    margin-right: auto;
  }

  .landingPageContainer {
    color: white;
    text-align: center;
    display: block;
  }

  .Logos {
    margin-left: auto;
    width: 350px;
  }
}
