/* .navbar {
  background-color: transparent;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin: 0 10px;
  font-size: 22px;
  color: white;
  transition: all 0.3s ease-in-out;
}

.nav-links li:hover {
  transform: translateY(-5px);
} */
/* 
.navbar {
  background-color: transparent;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
  float: left;
}

.navbar-link {
  font-weight: bold;
  margin-left: 1rem;
  float: right;
} */

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.navbar-animation {
  animation: slideDown 1s ease-in-out;
}
.navbar {
  background-color: transparent;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: x-large;
}

.brand-logo {
  font-size: 20px;
  font-weight: bold;
}

.nav-links {
  font-weight: bold;
  float: right !important;
  align-items: flex-end;
  margin-left: auto;
}

.socials {
}
.socials:hover {
  transform: translateY(-5px);
  background-color: transparent !important;

  transition: all 0.4s ease-in-out;
}
/* 
.nav-link:hover {
  animation: animate-link 0.5s ease-in-out;
} */

.nav-link:hover {
  color: #333 !important;
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

@keyframes animate-link {
  from {
    transform: translateX(px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

.nav-link.active {
  transform: translateY(-5px);
  color: #fff;
  text-decoration: overline;
}
