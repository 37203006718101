.welcome-page {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}
.subTitle {
  font-size: 25px;

  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  color: #fff;
}

.titleWelcome {
  font-size: 50px !important;

  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
}
@media (max-width: 560px) {
  .titleWelcome {
    font-size: 30px !important;
  }
  .subTitle {
    font-size: 18px;
  }

  .content {
    position: relative;
    width: 80%;
  }
}
