.landingPageContainerService {
  color: white;
  height: auto;
}

.bgContainerService {
  /* background-color: black !important; */
  /* background-size: 400% 400%;
  animation: gradient 15s ease infinite; */
}
.subtitleService {
  text-align: center;
  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
  font-size: larger !important;
  /* margin-right: 100px; */
}

.titleService {
  margin-top: 80px !important;
  text-align: center;
  /* margin-right: 100px; */
  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
  font-size: 40px !important;
}
/* .mylogoContainer {
  width: 1000px;
} */
.LogosServices {
  width: 49.5vw !important;
  float: left !important;
  height: 100vh;

  object-fit: cover;
}

.LogosServicesRev {
  width: 49.5vw !important;
  float: right !important;
  height: 100vh;
}
@media (max-width: 560px) {
  .titleService {
    font-size: 18px;
    margin-top: 60px;
    margin-right: auto;
    text-align: center;
  }

  .subtitleService {
    margin-right: auto;
    font-size: medium !important;
  }

  .landingPageContainerService {
    padding-top: 50px;
    color: white;
    text-align: center;
    display: block;
  }

  .LogosServices {
    border-radius: 20px;
    float: none !important;
    margin-right: auto;
    width: 350px !important;
    height: auto;
  }
  .LogosServicesRev {
    border-radius: 20px;
    margin-left: auto;
    float: none !important;
    width: 350px !important;
    height: auto;
  }
}
