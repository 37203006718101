.welcome-pageAboutUs {
  background-image: url("../../assets/aboutCargo2.jpg");
  background-size: cover;
  height: 100vh;
  margin-bottom: 10px;
}

.AboutUs-background {
  background-size: cover;
  height: 100vh;
}
.subTitleAboutUs {
  font-size: 25px;

  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
}

.AboutUsPageBg {
  background: linear-gradient(
    -45deg,
    #ececec,
    hsl(245, 73%, 29%),
    #000000,
    #151848,
    #010320
  );
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
}

.contentAboutUs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  color: #fff;
}

.subtitleAboutUsPage {
  text-align: center;
  color: #fff;
  font-weight: 50;
  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
  font-size: x-large !important;
}
.aboutUsPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px !important;
  height: 1200px;
  /* background-color: #151848; */
}
.titleAboutUs {
  margin-top: 80px !important;
  margin-bottom: 30px !important;
  text-align: center;
  color: #fff;
  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
  font-size: 40px !important;
}

.titleWelcomeAboutUs {
  font-size: 50px !important;

  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
}
@media (max-width: 560px) {
  .welcome-pageAboutUs {
    background-image: url("../../assets/aboutCargo2.jpg");
    background-size: cover;

    margin-bottom: 10px;
  }
  .aboutUsPageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
    height: auto;
    /* background-color: #151848; */
  }
  .titleWelcomeAboutUs {
    font-size: 50px !important;
  }
  .subTitleAboutUs {
    font-size: 18px;
    margin-bottom: 200px;
  }

  .contentAboutUs {
    width: 80%;
  }
}
