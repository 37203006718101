.welcome-pageService {
  background-image: url("../../assets/aboutCargo3.jpg");
  background-size: cover;
  /* height: 100vh; */
  margin-bottom: 10px;
}

.service-background {
  background-size: cover;
  height: 100vh;
}
.subTitleService {
  font-size: 25px;

  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
}

.servicePageBg {
  background: linear-gradient(
    -45deg,
    #ececec,
    hsl(245, 73%, 29%),
    #000000,
    #151848,
    #010320
  );
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  color: #fff;
}

.titleWelcomeService {
  font-size: 50px !important;

  text-shadow: 0 3px 10px rgb(0 0 0 / 60%) !important;
}
@media (max-width: 560px) {
  .welcome-pageService {
    background-image: url("../../assets/aboutCargo3.jpg");
    background-size: cover;

    margin-bottom: 10px;
  }
  .titleWelcomeService {
    font-size: 50px !important;
  }
  .subTitleService {
    font-size: 18px;
    margin-bottom: 200px;
  }

  .content {
    width: 80%;
  }
}
